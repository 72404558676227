<template>
  <div>
    <div style="margin-left: 20px;margin-right: 20px;font-size: 15px;">
      <div v-for="student in data" :key="student.exam_item_id" :id="student.exam_item_id" class="page-content">
        <div style="width: 100%;height: 24px;">
          <div style="display: block;float: left;" :caclu="num=0"><b>{{student.name}}(打印日期：{{date}})</b></div>
          <vue-qr style="display: block;float: right;margin-top: -10px;margin-right: -10px;" :size="100" :text="href + student.exam_item_id"></vue-qr>
        </div>
        <div style="width: 100%;float:left;" v-if="student.character.length">
          <p style="font-size: 30px;">会认字</p>
          <div v-for="(characterRead, index) in student.character" :key="index">
            <div v-if="characterRead.target === 1" style="width: 100px;float:left;">
              <div style="margin-top: 1px;" :caclu="num++">
                <span style="border:3px solid;border-color: #A8FF24;width: 60px;display: inline-block;">
                  <span>{{num}}</span>:
                </span>&nbsp;
                <div style="width: 50px;">
                  <img style="width: 60px;height: 40px;" src="/ueditor/dialogs/emotion/images/x/x_0002.gif"/>
                  <span style="font-size: 50px;" v-html="characterRead.character"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;margin-top: 50px;float:left;" v-if="student.character.length">
          <p style="font-size: 30px;">会写字</p>
          <div v-for="(characterWrite, index) in student.character" :key="index">
            <div v-if="characterWrite.target === 2" style="width: 100px;float:left;">
              <div style="margin-top: 1px;" :caclu="num++">
                <span style="border:3px solid;border-color: #A8FF24;width: 60px;display: inline-block;">
                  <span>{{num}}</span>:
                </span>&nbsp;
                <div style="width: 50px;">
                  <span style="font-size: 25px;" v-html="characterWrite.pinyin"></span>
                  <img style="width: 60px;height: 60px;" src="/ueditor/dialogs/emotion/images/x/x_0001.gif"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;float:left;" v-if="student.word">
          <p style="font-size: 30px;">默写词语</p>
          <div v-for="(word, index) in student.word" :key="index">
            <div style="margin-top: 1px;float: left;margin-right: 20px;" :caclu="num++">
              <div style="border:3px solid;border-color: #A8FF24;width: 60px;">
                {{num}}:
              </div>
              <div v-for="(c, index) in word.pinyin" :key="index" style="width: 71px;float: left;">
                <span style="font-size: 18px;width: 60px;display: block; text-align: center;" v-html="c"></span>
                <img style="opacity: 0.3;width: 60px;height: 60px;" src="/ueditor/dialogs/emotion/images/x/x_0001.gif"/>
              </div>
            </div>
          </div>
        </div>
        <div style="page-break-after: always;"></div>
        <div style="opacity: 0">草稿</div>
        <div style="page-break-after: always;"></div>
        <div style="opacity: 0">草稿</div>
        <div style="page-break-after: always;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueQr from 'vue-qr'
export default {
  mixins: [mixin(), propValue()],
  components: {
    VueQr
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      reg: /<p>/gi,
      href: 'https://wechat.widenteen.com/chinese_examination?id=',
      data: [],
      status: {
        questionFrom: []
      },
      date: new Date().toLocaleDateString()
    }
  },
  methods: {
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (Number(option[i].value) === Number(value)) {
          return option[i].label
        }
      }
    }
  },
  async created () {
    this.status.questionFrom = status.questionFrom
    const data = await this.getFetch('/api/chinese_examination/paper', { id: this.$route.query.id })
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].word.length; j++) {
        data[i].word[j].word = data[i].word[j].word.split('')
        data[i].word[j].pinyin = data[i].word[j].pinyin.split(',')
      }
    }
    this.data = data
  }
}
</script>

<style lang="css">
  .kfformula {
    display: inline-block;
    vertical-align: middle;
    margin-left: -20px;
    margin-right: -20px;
  }
  @page {
    margin-left: 50px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 30px;
    size: auto;
  }
  .page-content table
  {
    border-collapse: collapse;
    text-align: center;
    vertical-align: middle;
  }
  .page-content table,th, td
  {
    border: 1px solid black;
  }
</style>
